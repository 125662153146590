<template>
  <div class="main-wrap">
    <!-- 导航栏 -->
    <!-- 查询表单 -->
    <el-form :inline="true" :model="queryForm">
      <el-form-item>
        <el-input
          v-model="queryForm.name"
          placeholder="名称"
          clearable
        ></el-input>
      </el-form-item>
      <el-form-item>
        <el-radio-group v-model="queryForm.timeType" @change="queryData">
          <el-radio-button label="today">当天</el-radio-button>
          <el-radio-button label="yesterday">昨天</el-radio-button>
          <el-radio-button label="week">本周</el-radio-button>
          <el-radio-button label="month">本月</el-radio-button>
        </el-radio-group>
      </el-form-item>
      <el-form-item>
        <el-button type="primary" icon="el-icon-search" @click="queryData"
          >查询</el-button
        >
      </el-form-item>
      <el-form-item>
        <el-button @click="outPush">导出</el-button>
      </el-form-item>
    </el-form>
    <el-tabs v-model="queryForm.listType" type="card" @tab-click="handleClick">
      <el-tab-pane label="微信列表" name="1"></el-tab-pane>
      <el-tab-pane label="微信分组列表" name="2"></el-tab-pane>
      <el-tab-pane label="落地页分组列表" name="3"></el-tab-pane>
    </el-tabs>
    <el-table
      ref="tableData"
      v-loading="loading"
      border
      stripe
      :data="tableData"
      element-loading-text="加载中"
      element-loading-spinner="el-icon-loading"
      height="1000"
      @sort-change="changeSort"
    >
      <el-table-column
        type="index"
        label="序号"
        width="120"
        align="center"
      ></el-table-column>
      <el-table-column
        :label="nameType[queryForm.listType]"
        min-width="120"
        align="center"
        :prop="nameMap[queryForm.listType]"
      >
        <template slot-scope="scope">
          <div>{{ scope.row[nameMap[queryForm.listType]] }}</div>
        </template>
      </el-table-column>
      <el-table-column
        prop="dateStr"
        label="时间"
        min-width="120"
        align="center"
      ></el-table-column>
      <el-table-column
        prop="pageViewNums"
        label="PV"
        min-width="120"
        align="center"
        sortable="true"
      >
        <template slot-scope="scope">
          <div>{{ scope.row.pageViewNums ? scope.row.pageViewNums : 0 }}</div>
        </template>
      </el-table-column>
      <el-table-column
        prop="conversionAmount"
        label="转换量"
        min-width="120"
        sortable="true"
        align="center"
      >
        <template slot-scope="scope">
          <div>
            {{ scope.row.conversionAmount ? scope.row.conversionAmount : 0 }}
          </div>
        </template>
      </el-table-column>
    </el-table>
  </div>
</template>

<script>
export default {
  data() {
    return {
      queryForm: {
        listType: "1",
        name: "",
        pageNo: 1,
        pageSize: 10,
        sort: "",
        sortField: "",
        timeType: "today",
      },
      total: 0,
      tableData: [],
      loading: false,
      nameType: {
        1: "微信名称",
        2: "微信分组名称",
        3: "落地页名称",
      },
      nameMap: {
        1: "wechatNumber",
        2: "groupName",
        3: "groupName",
      },
      sortFieldMap: {
        pageViewNums: "pv",
        conversionAmount: "ca",
      },
      sortMap: {
        ascending: "asc",
        descending: "desc",
      },
    };
  },
  mounted() {
    if (this.$route.params.type) {
      this.queryForm.listType = this.$route.params.type + "";
      this.queryForm.name = this.$route.params.name;
    }
    this.queryData();
  },
  methods: {
    queryData() {
      this.loading = true;
      this.$http
        .get("/system/statistics/getPage", { params: this.queryForm })
        .then((response) => {
          if (response.data.code === 200) {
            this.tableData = response.data.data;
            this.total = response.data.count;
          }
        })
        .finally(() => {
          this.loading = false;
        });
    },
    changeSort(val) {
      this.queryForm.sort = this.sortMap[val.order];
      this.queryForm.sortField = this.sortFieldMap[val.prop];
      this.queryData();
    },
    handleClick() {
      this.queryData.sort = "";
      this.queryData.sortField = "";
      this.queryData();
      this.$refs.tableData.clearSort();
    },
    handleDetail() {},
    handleDelete() {
      this.$http
        .get("/system/template/getPage", { params: this.queryForm })
        .then((response) => {
          if (response.data.code === 200) {
            this.tableData = response.data.data;
            this.total = response.data.count;
          }
        })
        .finally(() => {
          this.loading = false;
        });
    },
    outPush() {
      this.$confirm("是否导出统计信息？", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        this.$http
          .get("/system/statistics/download", {
            params: this.queryForm,
            responseType: "blob",
          })
          .then((response) => {
            if (response.status == 200) {
              let content = response.headers["content-disposition"];
              let arr = content.split("=");
              let text = arr[1];
              let arrB = text.split(".");
              let textB = decodeURI(arrB[0]);
              const blob = new Blob([response.data], {
                type: response.headers["content-type"],
              });
              const fileName = textB.split("'")[2] + ".xlsx";
              const elink = document.createElement("a");
              elink.download = fileName;
              elink.style.display = "none";
              elink.href = URL.createObjectURL(blob);
              document.body.appendChild(elink);
              elink.click();
              URL.revokeObjectURL(elink.href);
              document.body.removeChild(elink);
            } else {
              this.$message.error("导出失败");
            }
          })
          .catch(() => {
            this.$message.error("未查询到需要导出的数据");
          });
      });
    },
  },
};
</script>

<style></style>
